import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import Heading from "@components/heading/heading"

class PricingPage extends React.Component {
  render() {
    const siteTitle = "Pricing"
    const siteDescription = "Pricing description"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SeoComponent title={siteTitle} description={siteDescription} />
        <Heading title="Pricing" />

        <div className="pb-10">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <h2 className="font-weight-bold text-gradient-blue mb-5">
                  <strong>WebriQ GLUE</strong> has a unit price of $1,000 per
                  month, and includes
                </h2>
                <Row>
                  <Col md={6}>
                    <ul className="checked-list">
                      <li>
                        Dedicated team of designers, developers, content
                        managers and devops
                      </li>
                      <li>Ongoing design, development and devops</li>
                      <li>Ecommerce Development</li>
                      <li>Serverless operation</li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="checked-list">
                      <li>Level 1 and 2 technical support</li>
                      <li>Level 1 and 2 user support</li>
                      <li>Maintenance and updates</li>
                      <li>Analytics </li>
                      <li>Security</li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="py-10 bg-light">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <h2 className="font-weight-bold text-gradient-blue mb-5 text-center">
                  How can we sustain this <strong>pricing model</strong>
                </h2>
                <p>
                  We have been designing and developing websites and web apps
                  for a long time, and we have seen all the deficiencies of
                  project based pricing, per hour pricing, unknowns in devops,
                  unknowns in maintenance, overruns, delays etc.
                </p>
                <p>
                  We have developed a very opinionated way of implementing
                  websites, ecommerce carts and webapps
                </p>
                <Row className="my-5">
                  <Col md={6}>
                    <ul className="checked-list">
                      <li>
                        We completely moved away from a monolithic and
                        centralized approach.
                      </li>
                      <li>
                        We believe in a decentralized approach whereby each
                        software component is chosen for it’s best in class, but
                        at the same time replaceable with a newer best in class
                        if needed.
                      </li>
                      <li>
                        Where we can not find a best in class critical
                        functionality that is open sourced, we develop it
                        ourselves, i.e. WebriQ Forms.
                      </li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="checked-list">
                      <li>We are strong supporters of the API economy.</li>
                      <li>
                        We strongly believe in microservices when it comes to
                        functionality and automation.
                      </li>
                      <li>
                        We aim at 100% serverless operation, and we know how to
                        do that.
                      </li>
                      <li>
                        We aim at 100% uptime, and we know how to do that.
                      </li>
                    </ul>
                  </Col>
                </Row>
                <p className="lead text-center">
                  We are highly specialized, and opinionated when it comes to
                  the technology stack used.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="pt-10 pb-5 text-center">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <h2 className="font-weight-bold display-5 text-center text-gradient-blue">
                  Want to know more?
                </h2>
                <p className="lead mb-5">
                  Schedule a short 15 minute call with Alex Belding
                </p>
                <a
                  href="https://webriq.zohobookings.com/#/customer/alex"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Schedule a Call
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default PricingPage
